@mixin responsive() {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

$items: 3;
$animation-time: 6s;
$transition-time: 1s;
$scale: 20%;

$total-time: ($animation-time * $items);
$scale-base-1: (1 + $scale / 100%);

.slideshow {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.title-slide-content {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #333;
  z-index: 9999;
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.6);
  padding: 2em;
  border-radius: 5px;

  h1 {
  
    font-size: 5em;
    margin: 0;

    @include responsive() {
      font-size: 2em;
    }
  }
  
  h2 {
    
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: normal;
    font-size: 2em;

    @include responsive() {
      font-size: 2em;
    }
  }
}



.slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: $total-time;
  opacity: 1;
  transform: scale($scale-base-1);

  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      animation-name: kenburns-#{$i};
      z-index: ($items - $i);
    }
  }
}

@for $i from 1 through $items {
  @keyframes kenburns-#{$i} {
    $animation-time-percent: percentage($animation-time / $total-time);
    $transition-time-percent: percentage($transition-time / $total-time);
    
    $t1: ($animation-time-percent * ($i - 1) - $transition-time-percent / 2);
    $t2: ($animation-time-percent * ($i - 1) + $transition-time-percent / 2);
    @if($t1 < 0%) { $t1: 0%; }
    @if($t2 < 0%) { $t2: 0%; }

    $t3: ($animation-time-percent * ($i) - $transition-time-percent / 2);
    $t4: ($animation-time-percent * ($i) + $transition-time-percent / 2);
    @if($t3 > 100%) { $t3: 100%; }
    @if($t4 > 100%) { $t4: 100%; }

    $t5: (100% - $transition-time-percent / 2);
    $t6: (($t4 - $t1) * 100% / $t5);

    #{$t1} { opacity: 1; transform: scale($scale-base-1); }
    #{$t2} { opacity: 1; }
    #{$t3} { opacity: 1; }
    #{$t4} { opacity: 0; transform: scale(1); }
    
    @if($i != $items) {
      100% { opacity: 0; transform: scale($scale-base-1); }
    }
    
    @if($i == 1) {
      $scale-plus: ($scale * (100% - $t5) / $t4);
      $scale-plus-base-1: (1 + ($scale + $scale-plus) / 100%);
    
      #{$t5} { opacity: 0; transform: scale($scale-plus-base-1); }
      100% { opacity: 1; }
    }
  }
}

.slideshow-image-1 {
  background-image: url('./img/slideshow1.jpg');
}

.slideshow-image-2 {
  background-image: url('./img/slideshow2.jpg');
}

.slideshow-image-3 {
  background-image: url('./img/slideshow3.jpg');
}