@import "https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One";
@import "https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One";
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Pathway Gothic One, sans-serif;
}

.text-slide {
  padding: 4em 0;
}

.text-slide .text-slide-inner {
  width: 800px;
  max-width: 100%;
  text-align: justify;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.8em;
}

.text-slide .text-slide-inner .logo {
  width: 90%;
  height: 100px;
  background-image: url("pmg.430d28b1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0 4em;
}

@media only screen and (width <= 767px) {
  .text-slide .text-slide-inner .logo {
    margin: 0 auto;
  }
}

.text-slide .text-slide-inner p {
  width: 80%;
  color: #333;
  margin: 0 auto 2em;
  font-family: Cardo;
}

.text-slide .text-slide-inner .pullout {
  width: 40%;
  font-family: Pathway Gothic One, sans-serif;
  font-size: 1.4em;
}

.text-slide .text-slide-inner .pullout.right {
  float: right;
  text-align: left;
  margin: 2em -20% 2em 2em;
}

@media only screen and (width <= 767px) {
  .text-slide .text-slide-inner .pullout.right {
    float: unset;
    text-align: center;
    margin: 2em 0;
  }
}

.text-slide .text-slide-inner .pullout.left {
  float: left;
  text-align: right;
  margin: 2em 2em 2em -20%;
}

@media only screen and (width <= 767px) {
  .text-slide .text-slide-inner .pullout.left {
    float: unset;
    text-align: center;
    margin: 2em 0;
  }

  .text-slide .text-slide-inner .pullout {
    width: 100%;
  }
}

.text-slide .text-slide-inner .pullout img {
  width: 100%;
  display: block;
}

.stats {
  height: 100vh;
  background-color: #ccc;
  position: relative;
}

.stats:before {
  content: "";
  width: 100%;
  height: 100%;
  filter: grayscale();
  opacity: .1;
  background-image: url("sa-parliament.b67d084d.jpg");
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.stats .stats-inner {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.stats .stats-inner h1 {
  margin: 0;
  font-size: 4em;
}

@media only screen and (width <= 767px) {
  .stats .stats-inner h1 {
    font-size: 2em;
  }
}

.stats .stats-inner h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1em;
  font-weight: normal;
}

.stats .stats-inner .stat-cards-row {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width <= 767px) {
  .stats .stats-inner .stat-cards-row {
    display: block;
  }
}

.stats .stats-inner .col {
  width: 100%;
  padding: 1em;
}

@media only screen and (width <= 767px) {
  .stats .stats-inner .col {
    margin: .5em 0;
  }
}

.stats .stats-inner .stat-card {
  width: 100%;
  color: #fff;
  background-color: #61aae5e6;
  border-radius: 5px;
  padding: 2em;
  box-shadow: 0 6.7px 5.3px #0000000c, 0 12.5px 10px #0000000f, 0 22.3px 17.9px #00000012, 0 41.8px 33.4px #00000016, 0 100px 80px #0000001f;
}

@media only screen and (width <= 767px) {
  .stats .stats-inner .stat-card {
    padding: 1em;
  }
}

.stats .stats-inner .stat-card .stat-card-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: normal;
}

.stats .stats-inner .stat-card .stat-card-number {
  font-size: 3em;
  font-weight: 700;
}

@media only screen and (width <= 767px) {
  .stats .stats-inner .stat-card .stat-card-number {
    font-size: 2em;
  }
}

footer {
  width: 800px;
  max-width: 100%;
  padding: 1em;
}

footer .KAS {
  height: 50px;
  display: inline-block;
}

footer .pmglogo {
  height: 45px;
  margin: 0 0 2px 1em;
  display: inline-block;
  position: relative;
}

.timeline {
  max-width: 700px;
  flex-flow: column wrap;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.timeline__content-title {
  box-sizing: border-box;
  color: #fff;
  margin: -10px 0 0;
  padding: 0 10px;
  font-family: Pathway Gothic One, sans-serif;
  font-size: 66px;
  font-weight: normal;
  transition: all .4s;
}

.timeline__content-desc {
  box-sizing: border-box;
  color: #fff;
  margin: 0;
  font-family: Pathway Gothic One, sans-serif;
  font-size: 23px;
  font-weight: normal;
  line-height: 25px;
}

.timeline:before {
  width: 2px;
  height: 100%;
  content: "";
  background: #ffffff12;
  margin-left: -1px;
  position: absolute;
  left: 50%;
}

@media only screen and (width <= 767px) {
  .timeline:before {
    left: 40px;
  }
}

.timeline-item {
  opacity: .3;
  filter: blur(2px);
  box-sizing: border-box;
  width: calc(50% - 40px);
  padding: 40px 0;
  transition: all .5s;
  display: flex;
  position: relative;
  transform: translateY(-80px);
}

.timeline-item:before {
  content: attr(data-text);
  letter-spacing: 3px;
  width: 100%;
  color: #f5ebad;
  text-transform: uppercase;
  opacity: 0;
  border-left: 2px solid #f5ebad;
  margin-top: -5px;
  padding-left: 15px;
  font-family: Pathway Gothic One, sans-serif;
  font-size: 24px;
  position: absolute;
  top: 70%;
  right: calc(-100% - 56px);
}

.timeline-item:nth-child(2n) {
  align-self: flex-end;
}

.timeline-item:nth-child(2n):before {
  text-align: right;
  border-left: none;
  border-right: 2px solid #f5ebad;
  padding-left: 0;
  padding-right: 15px;
  left: calc(-100% - 56px);
  right: auto;
}

.timeline-item--active {
  opacity: 1;
  filter: blur();
  transform: translateY(0);
}

.timeline-item--active:before {
  opacity: 1;
  transition: all .3s .2s;
  top: 50%;
}

.timeline-item--active .timeline__content-title {
  margin: -50px 0 20px;
}

@media only screen and (width <= 767px) {
  .timeline-item {
    width: 100%;
    padding: 0 30px 150px 80px;
    align-self: baseline !important;
  }

  .timeline-item:before {
    width: 60px;
    font-size: 18px;
    top: 50px;
    text-align: left !important;
    border: none !important;
    padding: 0 !important;
    left: 10px !important;
  }

  .timeline-item:last-child {
    padding-bottom: 40px;
  }
}

.timeline__img {
  max-width: 100%;
  box-shadow: 0 10px 15px #0006;
}

.timeline__bg {
  display: none;
}

.timeline-container {
  width: 100%;
  background-blend-mode: luminosity;
  background-color: #fff;
  background-size: cover;
  background-attachment: fixed;
  padding: 80px 0;
  transition: all .3s;
  position: relative;
}

.timeline-container:before {
  width: 100%;
  height: 100%;
  content: "";
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: linear-gradient(to right, #636363 30%, #636363cc 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.title-slide-content {
  text-align: center;
  color: #333;
  z-index: 9999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #fff9;
  border-radius: 5px;
  padding: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-slide-content h1 {
  margin: 0;
  font-size: 5em;
}

@media only screen and (width <= 767px) {
  .title-slide-content h1 {
    font-size: 2em;
  }
}

.title-slide-content h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 2em;
  font-weight: normal;
}

@media only screen and (width <= 767px) {
  .title-slide-content h2 {
    font-size: 2em;
  }
}

.slideshow-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: 50% / cover no-repeat;
  animation-name: kenburns;
  animation-duration: 18s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  transform: scale(1.2);
}

.slideshow-image:first-child {
  z-index: 2;
  animation-name: kenburns-1;
}

.slideshow-image:nth-child(2) {
  z-index: 1;
  animation-name: kenburns-2;
}

.slideshow-image:nth-child(3) {
  z-index: 0;
  animation-name: kenburns-3;
}

@keyframes kenburns-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }

  2.77778% {
    opacity: 1;
  }

  30.5556% {
    opacity: 1;
  }

  36.1111% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }

  97.2222% {
    opacity: 0;
    transform: scale(1.21538);
  }

  100% {
    opacity: 1;
  }
}

@keyframes kenburns-2 {
  30.5556% {
    opacity: 1;
    transform: scale(1.2);
  }

  36.1111% {
    opacity: 1;
  }

  63.8889% {
    opacity: 1;
  }

  69.4444% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-3 {
  63.8889% {
    opacity: 1;
    transform: scale(1.2);
  }

  69.4444% {
    opacity: 1;
  }

  97.2222% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.slideshow-image-1 {
  background-image: url("slideshow1.92f87b8d.jpg");
}

.slideshow-image-2 {
  background-image: url("slideshow2.6921defa.jpg");
}

.slideshow-image-3 {
  background-image: url("slideshow3.5cf5aa86.jpg");
}

.testimonials {
  background-color: #0000001a;
  padding: 100px 0;
}

.testimonials .testimonials-inner {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.testimonials .testimonials-inner h1 {
  text-align: center;
  margin: 0 0 100px;
  font-size: 4em;
}

@media only screen and (width <= 767px) {
  .testimonials .testimonials-inner h1 {
    font-size: 2em;
  }
}

.testimonial {
  width: 100%;
  margin: 0 0 4em;
}

.testimonial .testimonial-inner {
  background: #fff;
  border-radius: 8px;
  padding: 2em 3em 2em 6em;
  position: relative;
}

@media only screen and (width <= 767px) {
  .testimonial .testimonial-inner {
    padding: 1em;
  }
}

.testimonial .testimonial-inner:before {
  z-index: -1;
  content: "";
  width: 50%;
  max-width: 300px;
  background: #777;
  position: absolute;
  top: 80%;
  bottom: 15px;
  left: 10px;
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #777;
}

.testimonial .testimonial-inner .h3 {
  color: #333;
  margin: 0 0 10px;
  font-size: 20px;
}

.testimonial .testimonial-inner .testimonial-content {
  color: #333;
  font-family: Cardo;
  font-size: 16px;
  position: relative;
}

.testimonial .testimonial-inner .testimonial-content:before {
  content: "❝";
  color: #e0ddd799;
  font-size: 100px;
  position: absolute;
  top: -50px;
  left: -70px;
}

.photoalbum-container {
  padding: 2em;
}

.event {
  min-height: 100vh;
  background-color: #ccc;
  position: relative;
}

.event:before {
  content: "";
  width: 100%;
  min-height: 100%;
  filter: grayscale();
  opacity: .05;
  background-image: url("sa-parliament.b67d084d.jpg");
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.event .event-inner {
  width: 100%;
  text-align: center;
  padding: 4em 0;
  position: relative;
}

.event .event-inner img {
  outline: 4px solid #fff;
  box-shadow: 0 3px 10px #0009;
}

.event .event-inner h1 {
  margin: 0;
  font-size: 4em;
}

@media only screen and (width <= 767px) {
  .event .event-inner h1 {
    font-size: 2em;
  }
}

.event .event-inner h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.4em;
  font-weight: normal;
}

.yarl__fullsize {
  height: 100%;
  width: 100%;
}

.yarl__relative {
  position: relative;
}

.yarl__portal {
  opacity: 0;
  transition: opacity var(--yarl__fade_animation_duration, .25s) var(--yarl__fade_animation_timing_function, ease);
  z-index: var(--yarl__portal_zindex, 9999);
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.yarl__portal_open {
  opacity: 1;
}

.yarl__container {
  background-color: var(--yarl__container_background_color, var(--yarl__color_backdrop, #000));
  overscroll-behavior: var(--yarl__controller_overscroll-behavior, contain);
  touch-action: var(--yarl__controller_touch_action, none);
  -webkit-user-select: none;
  user-select: none;
  outline: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.yarl__carousel {
  height: 100%;
  opacity: var(--yarl__pull_down_opacity, 1);
  -webkit-transform: translate(var(--yarl__swipe_offset, 0), var(--yarl__pull_down_offset, 0));
  transform: translate(var(--yarl__swipe_offset, 0), var(--yarl__pull_down_offset, 0));
  width: calc(100% + (var(--yarl__carousel_slides_count)  - 1) * (100% + var(--yarl__carousel_spacing_px, 0) * 1px + var(--yarl__carousel_spacing_percent, 0) * 1%));
  flex: none;
  place-content: center;
  align-items: stretch;
  display: flex;
}

.yarl__carousel_with_slides {
  -webkit-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
  -moz-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
  column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
}

.yarl__flex_center {
  place-content: center;
  align-items: center;
  display: flex;
}

.yarl__slide {
  padding: calc(var(--yarl__carousel_padding_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_padding_percent, 0) * 1%);
  flex: 1;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .yarl__slide {
  --yarl__direction: -1;
}

.yarl__slide_image {
  -webkit-touch-callout: none;
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (width >= 800px) {
  .yarl__slide_image {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
  }
}

.yarl__slide_image_cover {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.yarl__slide_image_loading {
  opacity: 0;
}

.yarl__slide_placeholder {
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.yarl__slide_loading {
  color: var(--yarl__slide_icon_loading_color, var(--yarl__color_button, #fffc));
  animation: 1s linear yarl__delayed_fadein;
}

.yarl__slide_loading line {
  animation: 1s linear infinite yarl__stroke_opacity;
}

.yarl__slide_loading line:first-of-type {
  animation-delay: -1.875s;
}

.yarl__slide_loading line:nth-of-type(2) {
  animation-delay: -1.75s;
}

.yarl__slide_loading line:nth-of-type(3) {
  animation-delay: -1.625s;
}

.yarl__slide_loading line:nth-of-type(4) {
  animation-delay: -1.5s;
}

.yarl__slide_loading line:nth-of-type(5) {
  animation-delay: -1.375s;
}

.yarl__slide_loading line:nth-of-type(6) {
  animation-delay: -1.25s;
}

.yarl__slide_loading line:nth-of-type(7) {
  animation-delay: -1.125s;
}

.yarl__slide_loading line:nth-of-type(8) {
  animation-delay: -1s;
}

.yarl__slide_error {
  color: var(--yarl__slide_icon_error_color, red);
  height: var(--yarl__slide_icon_error_size, 48px);
  width: var(--yarl__slide_icon_error_size, 48px);
}

@media (prefers-reduced-motion) {
  .yarl__portal, .yarl__slide {
    transition: unset;
  }

  .yarl__slide_loading, .yarl__slide_loading line {
    -webkit-animation: unset;
    animation: unset;
  }
}

.yarl__toolbar {
  padding: var(--yarl__toolbar_padding, 8px);
  justify-content: flex-end;
  display: flex;
  position: absolute;
  inset: 0 0 auto auto;
}

[dir="rtl"] .yarl__toolbar {
  inset: 0 auto auto 0;
}

.yarl__icon {
  height: var(--yarl__icon_size, 32px);
  width: var(--yarl__icon_size, 32px);
}

.yarl__button {
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background-color: var(--yarl__button_background_color, transparent);
  border: var(--yarl__button_border, 0);
  color: var(--yarl__color_button, #fffc);
  cursor: pointer;
  -webkit-filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px #000c));
  filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px #000c));
  margin: var(--yarl__button_margin, 0);
  padding: var(--yarl__button_padding, 8px);
  outline: none;
  line-height: 0;
}

.yarl__button:focus {
  color: var(--yarl__color_button_active, #fff);
}

.yarl__button:focus:not(:focus-visible) {
  color: var(--yarl__color_button, #fffc);
}

.yarl__button:focus-visible {
  color: var(--yarl__color_button_active, #fff);
}

@media (hover: hover) {
  .yarl__button:focus-visible:hover, .yarl__button:focus:hover, .yarl__button:hover {
    color: var(--yarl__color_button_active, #fff);
  }
}

.yarl__button:disabled {
  color: var(--yarl__color_button_disabled, #fff6);
  cursor: default;
}

.yarl__navigation_next, .yarl__navigation_prev {
  padding: var(--yarl__navigation_button_padding, 24px 16px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.yarl__navigation_prev {
  left: 0;
}

[dir="rtl"] .yarl__navigation_prev {
  left: unset;
  right: 0;
  transform: translateY(-50%)rotate(180deg);
}

.yarl__navigation_next {
  right: 0;
}

[dir="rtl"] .yarl__navigation_next {
  left: 0;
  right: unset;
  transform: translateY(-50%)rotate(180deg);
}

.yarl__no_scroll {
  height: 100%;
  overscroll-behavior: none;
  overflow: hidden;
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes yarl__stroke_opacity {
  0% {
    stroke-opacity: 1;
  }

  to {
    stroke-opacity: .125;
  }
}

.yarl__slide_captions_container {
  background: var(--yarl__slide_captions_container_background, #00000080);
  left: var(--yarl__slide_captions_container_left, 0);
  padding: var(--yarl__slide_captions_container_padding, 16px);
  right: var(--yarl__slide_captions_container_right, 0);
  position: absolute;
  -webkit-transform: translateZ(0);
}

.yarl__slide_title {
  color: var(--yarl__slide_title_color, #fff);
  font-size: var(--yarl__slide_title_font_size, 125%);
  font-weight: var(--yarl__slide_title_font_weight, bolder);
  max-width: calc(100% - var(--yarl__toolbar_width, 0px));
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.yarl__slide_title_container {
  top: var(--yarl__slide_title_container_top, 0);
}

.yarl__slide_description {
  -webkit-line-clamp: var(--yarl__slide_description_max_lines, 3);
  color: var(--yarl__slide_description_color, #fff);
  -webkit-hyphens: auto;
  hyphens: auto;
  text-align: var(--yarl__slide_description_text_align, start);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.yarl__slide_description_container {
  bottom: var(--yarl__slide_description_container_bottom, 0);
}

/*# sourceMappingURL=index.ca23d9ba.css.map */
