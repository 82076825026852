@mixin responsive() {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

.testimonials {
    // width: 100%;
    // height: 100vh;
    // top: 0px;
    // left: 0px;
    // position: sticky !important;
    padding: 100px 0;
    background-color: rgba(0,0,0,0.1);

    .testimonials-inner {
        position: relative;
        width: 800px;
        max-width: 100%;
        margin: 0 auto;

        h1 {
            text-align: center;
            font-size: 4em;
            margin: 0 0 100px 0;

            @include responsive() {
                font-size: 2em;
            }
        }
    }
}




.testimonial {
    width: 100%;
    margin: 0 0 4em 0;
    

    .testimonial-inner {
        background: #fff;
        border-radius: 8px;
        padding: 2em 3em 2em 6em;
        position: relative;

        @include responsive() {
            padding: 1em;
        }

        &:before {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 15px;
            left: 10px;
            width: 50%;
            top: 80%;
            max-width:300px;
            background: #777;
            box-shadow: 0 15px 10px #777;
            transform: rotate(-3deg);
        }

        .h3 {
            color: #333;
            font-size: 20px;
            margin: 0 0 10px 0;
        }

        .testimonial-content {
            color: #333;
            font-size: 16px;
            font-family: Cardo;
            position: relative;

            &::before {
                content: '❝';
                position: absolute;
                top: -50px;
                left: -70px;
                font-size: 100px;
                color: rgba(224, 221, 215, 0.6);

            }


        }
    }
}  




