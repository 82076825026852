@import url('https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One');

// Responsive
@mixin responsive() {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Pathway Gothic One',sans-serif;
}


.text-slide {
    padding: 4em 0;

    .text-slide-inner {
        width: 800px;
        max-width: 100%;
        margin: 0 auto;
        font-size: 18px;
        text-align: justify;
        line-height: 1.8em;

        .logo {
            background-image: url('img/pmg.png');
            width: 90%;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin: 0 0 4em 0;

            @include responsive() {
                margin: 0 auto;
            }

            
        }

        p {
            width: 80%;
            margin: 0 auto 2em auto;
            color: #333;
            font-family: Cardo;
        }

        .pullout {

            &.right {
                float: right;
                margin: 2em -20% 2em 2em;
                text-align: left;

                @include responsive() {
                    float: unset;
                    text-align: center;
                    margin: 2em 0;
                }
            }

            &.left {
                float: left;
                margin: 2em 2em 2em -20%;
                text-align: right;

                @include responsive() {
                    float: unset;
                    text-align: center;
                    margin: 2em 0;
                }
            }

            width: 40%;
            font-size: 1.4em;
            font-family: 'Pathway Gothic One',sans-serif;

            @include responsive() {
                width: 100%;
            }
            

            img {
                width: 100%;
                display: block;
            }
        }
    }


}

.stats {
    height: 100vh;
    position: relative;
    background-color: #ccc;
    
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('img/sa-parliament.jpg');
        background-position: center center;
        background-size: cover;
        filter: grayscale(100%);
        opacity: 0.1;
    }

    .stats-inner {
        position: absolute;
        width: 100%;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);

        h1 {
            
            font-size: 4em;
            margin: 0;

            @include responsive() {
                font-size: 2em;
            }
        }

        h2 {
            
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: normal;
            font-size: 1em;
        }

        .stat-cards-row {
            display: flex;
            // flex-flow: row;
            width: 100%;
            max-width: 900px;
            margin: 0 auto;
            
            @include responsive() {
                display: block;
            }
        }

        .col {
            width:100%;
            padding: 1em;
            
            @include responsive() {
                margin: 0.5em 0;
            }
        }

        .stat-card {
            box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12);
            width: 100%;
            padding: 2em;
            background-color: rgba(97, 170, 229, 0.9);
            color: #fff;
            border-radius: 5px;

            @include responsive() {
                padding: 1em;
            }

            

            .stat-card-title {
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 3px;
            }

            .stat-card-number {
                font-weight: 700;
                font-size: 3em;

                @include responsive() {
                    font-size: 2em;
                }
            }
            
        }

    }

}

footer {
    width: 800px;
    max-width: 100%;
    padding: 1em;
    
    .KAS {
        height: 50px;
        display: inline-block;
    }

    .pmglogo {
        height: 45px;
        position: relative;
        display: inline-block;
        margin: 0 0 2px 1em;
    }
    
}


